import React, { memo, useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import { fade, useToastStore } from '@src/lib/toast/toast';
import styles from './toastNotification.module.scss';
import { useBasketStore } from '@src/store/useBasketStore';
import displayBasketErrors from '@src/lib/errorHandling/displayBasketErrors';
import { useBasketQuery } from '@src/api/useBasket';

function ToastNotificationWrapper() {
  // Need to subscribe to these separately due to Safari bug
  const addToast = useToastStore((state) => state.addToast);
  const removeToast = useToastStore((state) => state.removeToast);

  const { data } = useBasketQuery();
  const currentEngine = useBasketStore((state) => state.currentEngine);

  useEffect(() => {
    // Track toasts that belong to groups being added and removed
    const unsub = toast.onChange(({ status, containerId, id }) => {
      if (!containerId) return;
      if (status === 'added') addToast(containerId, id);
      if (status === 'removed') removeToast(containerId, id);
    });

    return () => unsub();
  }, [addToast, removeToast]);

  useEffect(() => {
    // Dismiss all toasts on navigation
    if (data?.data && currentEngine) {
      displayBasketErrors({
        basket: data?.data,
        engine: currentEngine,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentEngine]);

  return (
    <div className={styles.toastNotification}>
      <ToastContainer
        theme="colored"
        className="toastifyCustomContainer"
        bodyClassName="toastifyCustomBodyContainer"
        transition={fade}
        closeButton={false}
        draggable={false}
        closeOnClick={true}
      />
    </div>
  );
}

export default memo(ToastNotificationWrapper);
