// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* prettier-ignore */
/* eslint-disable */

/**
 * Initializes the Facebook Pixel base code by injecting the Facebook Pixel script
 * into the current page and setting up the `fbq` function on the `window` object.
 *
 * @see - https://developers.facebook.com/docs/meta-pixel/get-started/
 *
 * @function
 * @returns {void} This function does not return any value.
 */
export default function baseCode() {
  !function(f,b,e,v,n,t,s)
  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
  n.queue=[];t=b.createElement(e);t.async=!0;
  t.src=v;s=b.getElementsByTagName(e)[0];
  s.parentNode.insertBefore(t,s)}(window, document,'script',
  'https://connect.facebook.net/en_US/fbevents.js');
}
