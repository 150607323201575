import { ErrorBoundaryArgs } from '@components/ErrorBoundary';
import { isAxiosError } from 'axios';
import { HTTP_STATUS } from '@src/constants';

export function handleApiError({
  error,
  showBoundary,
}: {
  error: unknown;
  showBoundary: (error: ErrorBoundaryArgs) => void;
}) {
  if (!error) return;

  if (!isAxiosError(error)) {
    showBoundary({ template: 'default' });
    return;
  }

  if (error.response?.status === HTTP_STATUS.InternalServerError) {
    showBoundary({ error: error.message, template: 'default' });
  }
}
