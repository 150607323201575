import { BasketPaymentSelector, Button } from '@kaboodle-solutions/design-library';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { BasketCart } from '@src/components';
import { DEVICE_TABLET_BREAKPOINT } from '@src/constants';
import { FaShoppingBasket } from 'react-icons/fa';
import classNames from 'classnames';
import styles from './BasketSection.module.scss';
import tracking from '@src/lib/tracking';
import { useBasketQuery } from '@src/api/useBasket';
import { useBasketStore } from '@src/store/useBasketStore';
import useCurrencyFormatter from '@src/hooks/useCurrencyFormatter/useCurrencyFormatter';
import { useDynamicNavigate } from '@src/hooks/useDynamicNavigate';
import useInitResponseStore from '@src/store/persistent/initResponse/useInitResponseStore';
import { useLocation } from 'react-router-dom';
import useScreenBreakpoint from '@src/hooks/useScreenBreakpoint';
import { useTranslation } from 'react-i18next';
import displayBasketErrors from '@src/lib/errorHandling/displayBasketErrors';

// Used in conjunction with css slide-out animation
const cartDisplayTimeout = 500;

const BasketSection = () => {
  const { options } = useInitResponseStore(({ options }) => ({
    options,
  }));

  const { t } = useTranslation(['common', 'basket']);
  const { format } = useCurrencyFormatter();
  const { data: basketData, isLoading: isBasketLoading, hasEngineBaketItems } = useBasketQuery();
  const location = useLocation();
  const { navigateToNextEngine } = useDynamicNavigate();

  const {
    continueButtonCallback,
    continueButtonVariant,
    currentEngine,
    getBasketRequestState,
    isTicketStateDirty,
    enableContinueButton,
    mutateBasketStatus,
    showBasket,
    setBasketStore,
    showCompleteBookingLabel,
    showMonthlyPrice,
    toggleMonthlyPrice,
    updateBasketNavigation,
  } = useBasketStore();

  useEffect(() => {
    updateBasketNavigation(navigateToNextEngine, location.pathname);
  }, [location, updateBasketNavigation, navigateToNextEngine]);

  const [isExpanded, setIsExpanded] = useState<boolean>(showBasket);
  const { screenWidth } = useScreenBreakpoint();
  const isOnMobile = screenWidth < DEVICE_TABLET_BREAKPOINT;
  const showOnDesktop = !isOnMobile && !showBasket;

  useEffect(() => {
    setBasketStore({ showMonthlyPrice: options?.DISPLAY_PRICE_PRIORITY === 3 });
  }, [options?.DISPLAY_PRICE_PRIORITY, setBasketStore]);

  const setShowBasket = useCallback(
    (value: boolean) => {
      setBasketStore({
        showBasket: value,
      });
    },
    [setBasketStore]
  );

  const handleAddToBasket = useCallback(() => {
    getBasketRequestState ? getBasketRequestState() : undefined;
  }, [getBasketRequestState]);

  const toggleBasket = () => {
    setIsExpanded(!isExpanded);
  };

  // Based on `isExpanded`, if false and on mobile allow the CSS transiton to happen before
  // updating basket store state
  useEffect(() => {
    if (!isExpanded) {
      setTimeout(
        () => {
          setShowBasket(false);
        },
        isOnMobile ? cartDisplayTimeout : 0
      );
    } else {
      setShowBasket(true);
    }
  }, [isExpanded, setShowBasket, isOnMobile]);

  // Ensure the local `isExpanded` state is always in sync with
  // Basket Store's `showBasket`.
  useEffect(() => {
    setIsExpanded(showBasket);
  }, [showBasket, setIsExpanded]);

  const handleContinue = useCallback(() => {
    // Always check for basket errors on continue click to refresh toasts
    if (basketData) {
      displayBasketErrors({
        basket: basketData?.data,
        engine: currentEngine,
        trigger: 'continue',
      });
    }

    if (enableContinueButton) {
      if (continueButtonCallback) continueButtonCallback();
      setIsExpanded(false);
      tracking.sendEvent({ event: 'basket_continue', engine: currentEngine || '', route: location.pathname });
    }
  }, [continueButtonCallback, setIsExpanded, currentEngine, location.pathname, basketData, enableContinueButton]);

  useEffect(() => {
    if (!hasEngineBaketItems) {
      setShowBasket(false);
    }
  }, [hasEngineBaketItems, setShowBasket]);

  const addToBasketLabel = useMemo(() => {
    if (mutateBasketStatus === 'pending') {
      return { label: t('common:loading'), testId: 'loading' };
    }

    return { label: t('common:addToBasket'), testId: 'add-to-basket' };
  }, [t, mutateBasketStatus]);

  const basketLoaded = !isBasketLoading;

  if (!basketLoaded) {
    return null;
  }

  const continueButtonProps = {
    id: 'basket-section-continue-btn',
    disabled: !basketData?.data || mutateBasketStatus === 'pending',
    testId: 'basket-section-continue-btn',
    labelText: showCompleteBookingLabel ? t('common:completeBooking') : t('common:continue'),
    onClick: handleContinue,
    variant: !enableContinueButton ? 'disabled' : isTicketStateDirty ? 'secondary' : continueButtonVariant,
  };

  return (
    <>
      {/* Expanded Cart */}
      {showBasket && (
        <BasketCart continueButtonProps={continueButtonProps} isExpanded={isExpanded} toggleBasket={toggleBasket} />
      )}
      {/* Collapsed Basket/Empty Basket (Desktop) || Basket/Empty Basket Footer Mobile */}
      {(showOnDesktop || isOnMobile) && (
        <div
          className={classNames(styles.basketContainer, {
            [styles.basketContainer__overlay]: showBasket,
          })}
        >
          {/* Add to basket button section */}
          <div
            className={classNames(
              {
                [styles.showUpdateBasketContainer]: isTicketStateDirty,
                [styles.hideUpdateBasketContainer]: !isTicketStateDirty,
              },
              styles.updateBasketContainer
            )}
          >
            <Button
              id="basket-section-add-to-basket-btn"
              testId={`basket-section-${addToBasketLabel.testId}-btn`}
              leftIcon={<FaShoppingBasket />}
              onClick={handleAddToBasket}
              labelText={addToBasketLabel.label}
              variant="primary"
            />
          </div>

          <div className={styles.basketContent}>
            <div className={styles.viewBasketCompleteBookingWrapper}>
              <div className={styles.viewBasketCompleteBooking_container}>
                {/* Basket Toggle & Payment Total */}
                <div className={styles.viewBasketAndTotal}>
                  <BasketPaymentSelector
                    basketValue={
                      showMonthlyPrice
                        ? `${format(basketData?.data?.calculated?.total?.monthlyPrice ?? 0, true)}`
                        : format(basketData?.data?.calculated?.total?.value ?? 0, true)
                    }
                    showPaymentSwitch={!!basketData?.data?.calculated?.total?.monthlyPrice}
                    onToggleBasket={toggleBasket}
                    onToggleSwitch={toggleMonthlyPrice}
                    isPrepayment={showMonthlyPrice}
                    labelPrePayment={t('basket:prePaymentPlanlabel')}
                    labelPrePaymentAbbrv={t('basket:prePaymentPerMonthAbbrv')}
                    labelTotal={t('common:words.total')}
                    fullWidth={!isOnMobile}
                    disabled={!hasEngineBaketItems}
                    testId="basket-section-view-basket-selector"
                  />
                </div>
                {/* Continue Desktop */}
                <div className={styles.basketContainer_footer}>
                  <div className={styles.basketContainer_continueWrapper}>
                    {!isOnMobile && <Button {...continueButtonProps} wide />}
                  </div>
                </div>
              </div>
              {/* Continue Mobile */}
              {isOnMobile && (
                <div className={styles.basketContainer_continueWrapper}>
                  <Button {...continueButtonProps} />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default BasketSection;
