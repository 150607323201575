import { Button, Typography } from '@kaboodle-solutions/design-library';

import { RESTART_ROUTE } from '@src/constants';
import { findFirstEngine } from '@src/lib/findFirstEngine';
import { useBasketQuery } from '@src/api/useBasket';
import { useCloseKaboodleLogoOnMount } from './Templates.helper';
import { useDeleteBasket } from '@src/hooks/useDeleteBasket';
import { useEffect } from 'react';
import useInitResponseStore from '@src/store/persistent/initResponse/useInitResponseStore';
import { useNavigate } from 'react-router-dom';
import { useNavigateWithQueryParams } from '@src/hooks/useNavigateWithQueryParams/useNavigateWithQueryParams';
import { useRestartBooking } from '@src/hooks/useRestartBooking';
import { useTranslation } from 'react-i18next';
import InfoCard from '@src/components/InfoCard/InfoCard';
import styles from './Templates.module.scss';

const ERROR_BOUNDARY_TEMPLATES = {
  default: (_error: string) => <GenericErrorTemplate />,
  // TODO: Create this error template
  noTokensFound: (_error: string) => <GenericErrorTemplate enableBasket={false} />,
  pageNotFound: (_error: string) => <PageNotFound />,
  basketError: (_error: string) => <BasketError />,
  paymentFailed: (_error: string) => <PaymentFailed />,
  zeroValueBasketError: (_error: string) => <ZeroValueBasketError />,
  packageNotOnSale: (_error: string) => <PackageNotOnSale />,
  initFailure: () => <InitFailure />,
  multipleBookingsError: () => <MultipleBookingsError />,
  accessCodeInvalid: () => <AccessCodeInvalidLink />,
  accessCodeInUse: () => <AccessCodeInUse />,
};
type TEMPLATE_KEYS = keyof typeof ERROR_BOUNDARY_TEMPLATES;

// Reusable ErrorDisplayBox component
function ErrorDisplayBox({
  title,
  content,
  actions,
}: {
  title: string;
  content: React.ReactNode;
  actions?: React.ReactNode;
}) {
  return (
    <div className={styles.statusContainer}>
      <div className={styles.templateWrapper}>
        <InfoCard title={title}>
          {content}
          {actions && <div className={styles.actionBox}>{actions}</div>}
        </InfoCard>
      </div>
    </div>
  );
}

/**
 * Templates for use in the error boundary. Once created, they can be added to the
 * ERROR_BOUNDARY_TEMPLATES object for use.
 */

function GenericErrorTemplate({ enableBasket = true }) {
  const { t } = useTranslation('errors');
  const { data: basketData } = useBasketQuery(enableBasket);
  const restartBooking = useRestartBooking({ basketData: basketData?.data });
  const { options, currency, engines } = useInitResponseStore(({ options, currency, engines }) => ({
    options,
    currency,
    engines,
  }));

  useCloseKaboodleLogoOnMount();
  const actions =
    options && currency && engines ? (
      <Button id="restart-booking-btn" labelText={t('restartBooking')} onClick={() => restartBooking()} />
    ) : null;

  return (
    <ErrorDisplayBox title={t('404Title')} content={<Typography>{t('cantResume')}</Typography>} actions={actions} />
  );
}

function InitFailure() {
  const { t } = useTranslation('errors');
  useCloseKaboodleLogoOnMount();
  return <ErrorDisplayBox title={t('404Title')} content={<Typography>{t('initFailure')}</Typography>} />;
}

function PageNotFound() {
  const { t } = useTranslation('errors');
  useCloseKaboodleLogoOnMount();
  return <ErrorDisplayBox title={t('pageNotFound')} content={<Typography>{t('incorrectInfo')}</Typography>} />;
}

function PackageNotOnSale() {
  useCloseKaboodleLogoOnMount();
  const { t } = useTranslation('errors');
  return (
    <ErrorDisplayBox
      title={t('notOnSale')}
      content={<Typography>{t('checkOtherEvents')}</Typography>}
      actions={<a href="https://kaboodle.com/events">{t('goToAvailableEvents')}</a>}
    />
  );
}

function BasketError() {
  const { t } = useTranslation('errors');
  const restartBooking = useRestartBooking({ basketData: null });

  useCloseKaboodleLogoOnMount();

  return (
    <ErrorDisplayBox
      title={t('basketError')}
      content={<Typography>{t('tryAgain')}</Typography>}
      actions={<Button id="back-to-start-btn" labelText={t('restartBooking')} onClick={() => restartBooking()} />}
    />
  );
}

function PaymentFailed() {
  const { t } = useTranslation('errors');
  useCloseKaboodleLogoOnMount();
  return <ErrorDisplayBox title={t('paymentFailed')} content={<Typography>{t('tryAgain')}</Typography>} />;
}

function AccessCodeInvalidLink() {
  const { t } = useTranslation('errors');
  useCloseKaboodleLogoOnMount();
  return (
    <ErrorDisplayBox
      title={t('accessCodeInvalidLinkTitle')}
      content={
        <>
          <Typography>{t('accessCodeInvalidLinkMessage1')}</Typography>
          <Typography>{t('accessCodeInvalidLinkMessage2')}</Typography>
        </>
      }
    />
  );
}

function AccessCodeInUse() {
  const { t } = useTranslation('errors');
  useCloseKaboodleLogoOnMount();
  return (
    <ErrorDisplayBox
      title={t('accessCodeInUseTitle')}
      content={<Typography>{t('accessCodeInUseMessage')}</Typography>}
    />
  );
}

function ZeroValueBasketError() {
  const { t } = useTranslation('errors');
  const { navigateWithQueryParams } = useNavigateWithQueryParams();
  const navigate = useNavigate();

  const engines = useInitResponseStore((state) => state.engines);

  function handleClick() {
    const firstRoute = findFirstEngine(engines ?? [])?.route;
    navigateWithQueryParams(firstRoute || RESTART_ROUTE);
    navigate(0);
  }

  useCloseKaboodleLogoOnMount();

  return (
    <ErrorDisplayBox
      title={t('couldntConfirmBooking')}
      content={<Typography>{t('tryAgain')}</Typography>}
      actions={<Button labelText={t('backToStart')} onClick={handleClick} />}
    />
  );
}

function MultipleBookingsError() {
  const { t } = useTranslation('errors');
  const restartBooking = useRestartBooking({ basketData: null });
  const { deleteBasketRequest } = useDeleteBasket();

  useEffect(() => {
    deleteBasketRequest();
  }, [deleteBasketRequest]);

  return (
    <ErrorDisplayBox
      title={t('multipleBookingsError')}
      content={<Typography> </Typography>}
      actions={<Button id="back-to-start-btn" labelText={t('restartBooking')} onClick={() => restartBooking()} />}
    />
  );
}

export { ERROR_BOUNDARY_TEMPLATES, GenericErrorTemplate };
export type { TEMPLATE_KEYS };
