import { QK_APP_BASKET, QK_SHOW_3_DOTS_LOADING } from '@src/api/queryKeys';
import { BaseBasket } from '@src/api/useBasket/useBasket.types';
import { CLICK_ID_METADATA_KEY, FBC_COOKIE_NAME, KABOODLE_FALLBACK_FBC_COOKIE_NAME } from '@src/constants';
import { UpdateBasketRequestBody } from '@src/interfaces/engineBasketPutRequest';
import { type QueryClient } from '@tanstack/react-query';
import { type AxiosResponse } from 'axios';
import Cookies from 'js-cookie';

/**
 * Attaches the Meta Pixel Click ID to the basket payload's metadata if it's available in the cookies.
 * If the exact same Click ID is already present in the basket metadata or not available in cookies,
 * it returns the existing payload without modification.
 *
 * @param {Partial<UpdateBasketRequestBody>} payload - The original basket payload that will be sent to the mutation.
 * @param {QueryClient} queryClient - The TanStack Query client instance used to access the cached query data.
 *
 * @returns {Partial<UpdateBasketRequestBody>} The updated payload with the Meta Pixel Click ID attached to the metadata
 *         if it's available, or the original payload if the Click ID is not present or already matches.
 */
export default function attachMetaClickIdToBasketPayload(
  payload: Partial<UpdateBasketRequestBody>,
  queryClient: QueryClient
) {
  // If no click ID in cookies, return the existing payload
  const clickIdCookie = Cookies.get(FBC_COOKIE_NAME) || Cookies.get(KABOODLE_FALLBACK_FBC_COOKIE_NAME);
  if (!clickIdCookie) return payload;

  const currentMetadata = queryClient.getQueryData<AxiosResponse<BaseBasket | null>>([
    QK_APP_BASKET,
    QK_SHOW_3_DOTS_LOADING,
  ])?.data?.metadata;

  // Return early if there's no metadata or click ID is the same
  if (currentMetadata?.[CLICK_ID_METADATA_KEY] && currentMetadata[CLICK_ID_METADATA_KEY] === clickIdCookie) {
    return payload;
  }

  // Otherwise, attach the new click ID to the payload's metadata
  return {
    ...payload,
    metadata: {
      ...currentMetadata,
      ...payload.metadata,
      [CLICK_ID_METADATA_KEY]: clickIdCookie,
    },
  };
}
