import { useBasketMutation, useBasketQuery } from '@src/api/useBasket';
import { useCallback, useEffect, useRef, useState } from 'react';

import { KapiEngineHandlesOptions } from '@src/interfaces/engineData';
import { useErrorBoundary } from '@src/components/ErrorBoundary';
import useInitResponseStore from '@src/store/persistent/initResponse/useInitResponseStore';
import { useSearchParams } from 'react-router-dom';

type HandleAccessCodeProps = {
  hasInitFinished: boolean;
};

export const useHandleAccessCode = ({ hasInitFinished }: HandleAccessCodeProps) => {
  const { mutateAsync } = useBasketMutation();
  const isFirstExecution = useRef(true);
  const [isAccessCodeReady, setIsAccessCodeReady] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    setAccessCode,
    accessCode: persistentAccessCode,
    _hasInitResponseStoreHydrated,
    options,
    setInitParams,
  } = useInitResponseStore();
  const isGatedAccessEnabled = Boolean(options?.GATED_ACCESS);
  const { showBoundary } = useErrorBoundary();
  const { data: basketData, isSuccess: isBasketCallSuccess } = useBasketQuery(isGatedAccessEnabled && hasInitFinished);

  const removeAccessCodeFromUrl = useCallback(
    (clearAccessCodeStorage = false) => {
      if (searchParams.get('ac')) {
        searchParams.delete('ac');
        setSearchParams(searchParams);
        setInitParams(`?${searchParams}`);
      }

      if (clearAccessCodeStorage) {
        setAccessCode(null);
      }
    },
    [setAccessCode, searchParams, setSearchParams, setInitParams]
  );

  useEffect(() => {
    const processAccessCode = async () => {
      if (!isGatedAccessEnabled) {
        removeAccessCodeFromUrl(true);
        setIsAccessCodeReady(true);
        isFirstExecution.current = false;
      }

      if (isGatedAccessEnabled) {
        const accessCode = searchParams.get('ac') || persistentAccessCode;

        if (!accessCode) {
          isFirstExecution.current = false;
          showBoundary({ template: 'accessCodeInvalid' });
        }

        if (accessCode) {
          if (!basketData?.data && isBasketCallSuccess) {
            await mutateAsync({ accessCode });
          }

          if (basketData?.data) {
            if (basketData.data.errors?.length) {
              removeAccessCodeFromUrl(true);
              isFirstExecution.current = false;
              const accessCodeError = basketData.data.errors.find(
                (error) => error.engine === KapiEngineHandlesOptions.ACCESS_CODE
              );

              // TODO - Update this check to use handles instead of error messages - KAPI to provide unique handles
              if (accessCodeError?.message === 'Code already in use.') {
                showBoundary({ template: 'accessCodeInUse' });
                return;
              }

              if (accessCodeError?.message === 'Given access code not available or does not exist.') {
                showBoundary({ template: 'accessCodeInvalid' });
                return;
              }

              if (accessCodeError?.message === 'Code has already been used.') {
                showBoundary({ template: 'accessCodeInvalid' });
                return;
              }

              showBoundary({ template: 'default' });
            }

            if (!basketData.data.errors?.length) {
              setAccessCode(accessCode);
              setIsAccessCodeReady(true);

              removeAccessCodeFromUrl();
              isFirstExecution.current = false;
            }
          }
        }
      }
    };

    if (isFirstExecution.current && hasInitFinished && _hasInitResponseStoreHydrated && options) {
      processAccessCode();
    }
  }, [
    _hasInitResponseStoreHydrated,
    basketData?.data,
    hasInitFinished,
    isBasketCallSuccess,
    isGatedAccessEnabled,
    mutateAsync,
    persistentAccessCode,
    removeAccessCodeFromUrl,
    searchParams,
    setAccessCode,
    showBoundary,
    options,
  ]);

  return { isAccessCodeReady };
};
