import { type AxiosResponse } from 'axios';
import { type Namespace, type TFunction } from 'i18next';
import { MutateOptions } from '@tanstack/react-query';

import { ServiceMessageVariants } from '@kaboodle-solutions/design-library';
import { BaseBasket, BasketAccommodation, BasketExtra, BasketTicket } from '@src/api/useBasket/useBasket.types';
import { basketHolder, BasketTransport, UpdateBasketRequestBody } from '@src/interfaces/engineBasketPutRequest';
import { isBasketAccommodationItem, isBasketExtrasItem } from '@src/interfaces/typeguards/isBasketItemType';

import { toastNotification } from '@src/lib/toast/toast';
import {
  BasketAccommodationItem,
  type BasketExtraItem,
  type BasketTicketItem,
  BasketTransportItem,
} from './BasketContent.types';
import { isBasketTransportItem } from '@components/Basket/BasketContent/BasketCartItem.helper';

export const generateBasketTicketsItem = (tickets: BasketTicket[] | null | undefined) =>
  tickets?.reduce((cartItems: BasketTicketItem[], item) => {
    const existingBasketItem = cartItems.find((t) => t.typeId === item.typeId);

    if (existingBasketItem) {
      existingBasketItem.count += 1;
      existingBasketItem.holders?.push({
        firstName: item.holder?.firstName ?? '',
        lastName: item.holder?.lastName ?? '',
      });
    } else {
      cartItems.push({
        ...item,
        count: 1,
        holders: [
          {
            firstName: item.holder?.firstName ?? '',
            lastName: item.holder?.lastName ?? '',
          },
        ],
      });
    }

    return cartItems;
  }, []) ?? [];

export const generateBasketExtrasItem = (selectedExtras: BasketExtra[] | null | undefined) =>
  selectedExtras?.reduce((cartItems: BasketExtraItem[], item) => {
    const existingBasketItem = cartItems.find((t) => t.optionId === item.optionId);

    if (existingBasketItem) {
      existingBasketItem.count += 1;
    } else {
      cartItems.push({
        ...item,
        count: 1,
      });
    }

    return cartItems;
  }, []) ?? [];

export const generateBasketAccommodationItem = (accommodations: BasketAccommodation[] | null | undefined) =>
  accommodations?.reduce((cartItems: BasketAccommodationItem[], item) => {
    const existingBasketItem = cartItems.find((t) => t.ruleId === item.ruleId);
    // Should accumulate any accoms with no extras
    const shouldAccumulate = item.extras.length === 0 && !!existingBasketItem && existingBasketItem.extras.length === 0;

    if (shouldAccumulate) {
      existingBasketItem.count += 1;
    } else {
      // Count extras for new accommodation item
      const extrasWithCount = item.extras.reduce((acc: BasketExtraItem[], extra) => {
        const existingExtra = acc.find((e) => e.optionId === extra.optionId);
        if (existingExtra) {
          existingExtra.count += 1;
        } else {
          acc.push({ ...extra, count: 1 });
        }
        return acc;
      }, []);

      cartItems.push({
        ...item,
        count: 1,
        extras: extrasWithCount,
        ...(!existingBasketItem ? { showAccommName: true } : {}),
      });
    }

    return cartItems;
  }, []) ?? [];

export const generateBasketTransportItem = (transports?: BasketTransport[]): BasketTransportItem[] => {
  if (!transports?.length) return [];

  const groupedTransports = transports.reduce((acc, transport) => {
    const existingGroup = acc.get(transport.routeId);
    if (existingGroup) {
      existingGroup.count++;
    } else {
      acc.set(transport.routeId, {
        routeId: transport.routeId,
        routeName: transport.routeName || '',
        legs: transport.legs.map((leg) => ({
          type: leg.type,
          stopId: leg.stopId,
          departureLocation: leg.departureName,
          arrivalLocation: leg.arrivalName,
          price: leg.price ?? { value: 0, bookingFee: 0 },
          time: leg.time,
          date: leg.date,
          transportType: transport.type,
        })),
        count: 1,
      });
    }
    return acc;
  }, new Map());

  return Array.from(groupedTransports.values());
};

const getPropertiesFromBasketItem = (
  basketItem: BasketTicketItem | BasketExtraItem | BasketAccommodationItem | BasketTransportItem
): { itemId: number; itemCount: number; itemName: string } => {
  let itemId: number, itemCount: number, itemName: string;

  if (isBasketExtrasItem(basketItem)) {
    itemId = basketItem.optionId;
    itemCount = basketItem.count;
    itemName = basketItem.extraName;
  } else if (isBasketAccommodationItem(basketItem)) {
    itemId = basketItem.ruleId;
    itemCount = basketItem.count;
    itemName = basketItem.accommodationName;
  } else if (isBasketTransportItem(basketItem)) {
    itemId = basketItem.routeId;
    itemCount = basketItem.count;
    itemName = basketItem.routeName;
  } else {
    itemId = basketItem.typeId;
    itemCount = basketItem.count;
    itemName = basketItem.name;
  }

  return { itemCount, itemId, itemName };
};

const fireItemRemovedToastFeedback = async (
  promise: Promise<unknown>,
  itemCount: number | null,
  itemName: string | null,
  t: TFunction<Namespace, undefined, Namespace>
) => {
  promise
    .then(() => {
      toastNotification({
        content: `${itemCount} x ${itemName} ${t('ticketsEngine:basketSection.toastRemovedFromBasket')}`,
        variant: ServiceMessageVariants.Success,
        testId: 'remove-from-basket-success',
      });
    })
    .catch(() => {
      toastNotification({
        content: t('ticketsEngine:basketSection.toastErrorFailedToRemove'),
        variant: ServiceMessageVariants.Error,
        testId: 'remove-from-basket-failure',
      });
    });

  return promise;
};

export const removeTicketsBasketItem = async (
  ticketBasketItemToRemove: BasketTicketItem,
  currentTicketBasketItems: BasketTicketItem[],
  mutateAsync: (
    variables: Partial<UpdateBasketRequestBody>,
    options?:
      | MutateOptions<AxiosResponse<BaseBasket, unknown>, Error, Partial<UpdateBasketRequestBody>, void>
      | undefined
  ) => Promise<AxiosResponse<BaseBasket, unknown>>,
  t: TFunction<Namespace, undefined, Namespace>
) => {
  const { itemCount, itemId, itemName } = getPropertiesFromBasketItem(ticketBasketItemToRemove);

  const newBasketItems = currentTicketBasketItems.filter((currentItem) => {
    return currentItem.typeId !== itemId;
  });

  const reduceInitialValue: { typeId: number }[] = [];
  const payload: { typeId: number; holder?: basketHolder }[] = newBasketItems.reduce(
    (acc, ticketBasketItemToRemove) => [
      ...acc,
      ...Array.from({ length: ticketBasketItemToRemove.count }, () => ({ typeId: ticketBasketItemToRemove.typeId })),
    ],
    reduceInitialValue
  );

  await fireItemRemovedToastFeedback(mutateAsync({ tickets: payload }), itemCount, itemName, t);
};

export const removeTransportBasketItem = async (
  transportBasketItemToRemove: BasketTransportItem,
  currentTransportBasketItems: BasketTransportItem[],
  basketTransport: BasketTransport | null | undefined,
  setBasketTransport: (transport: BasketTransport | null) => void,
  mutateAsync: (
    variables: Partial<UpdateBasketRequestBody>,
    options?:
      | MutateOptions<AxiosResponse<BaseBasket, unknown>, Error, Partial<UpdateBasketRequestBody>, void>
      | undefined
  ) => Promise<AxiosResponse<BaseBasket, unknown>>,
  t: TFunction<Namespace, undefined, Namespace>
) => {
  const { itemCount, itemName } = getPropertiesFromBasketItem(transportBasketItemToRemove);

  const newBasketItems = currentTransportBasketItems.filter((currentItem) => {
    return currentItem.routeId !== transportBasketItemToRemove.routeId;
  });

  const payload = newBasketItems.flatMap((item) =>
    Array.from({ length: item.count }, () => ({
      routeId: item.routeId,
      legs: item.legs.map((leg) => ({
        type: leg.type,
        stopId: leg.stopId,
        flightNumber: null,
      })),
    }))
  );

  await fireItemRemovedToastFeedback(mutateAsync({ transports: payload }), itemCount, itemName, t).then(() => {
    if (basketTransport && basketTransport.routeId === transportBasketItemToRemove.routeId) {
      setBasketTransport(null);
    }
  });
};

export const removeAccommodationBasketItem = async (
  accommodationBasketItemToRemove: BasketAccommodationItem,
  currentAccommodationBasketItems: BasketAccommodationItem[],
  mutateAsync: (
    variables: Partial<UpdateBasketRequestBody>,
    options?:
      | MutateOptions<AxiosResponse<BaseBasket, unknown>, Error, Partial<UpdateBasketRequestBody>, void>
      | undefined
  ) => Promise<AxiosResponse<BaseBasket, unknown>>,
  t: TFunction<Namespace, undefined, Namespace>,
  idx: number
) => {
  const { itemCount, itemName } = getPropertiesFromBasketItem(accommodationBasketItemToRemove);

  const getNewBasketItems = () => {
    return currentAccommodationBasketItems.filter((_item, index) => index !== idx);
  };

  const newItems = getNewBasketItems();
  const payload = newItems.flatMap((item) =>
    Array.from({ length: item.count }, () => ({
      ruleId: item.ruleId,
      extras: item.extras.map((extra) => ({
        optionId: extra.optionId,
        textInput: extra.textInput || undefined,
        declaration: extra.declaration || undefined,
      })),
    }))
  );

  await fireItemRemovedToastFeedback(mutateAsync({ accommodations: payload }), itemCount, itemName, t);
};

export const removeExtrasBasketItem = async (
  extrasBasketItemToRemove: BasketExtraItem,
  currentExtrasBasketItems: BasketExtraItem[],
  mutateAsync: (
    variables: Partial<UpdateBasketRequestBody>,
    options?:
      | MutateOptions<AxiosResponse<BaseBasket, unknown>, Error, Partial<UpdateBasketRequestBody>, void>
      | undefined
  ) => Promise<AxiosResponse<BaseBasket, unknown>>,
  t: TFunction<Namespace, undefined, Namespace>
) => {
  const { itemCount, itemId, itemName } = getPropertiesFromBasketItem(extrasBasketItemToRemove);

  const newBasketItems = currentExtrasBasketItems.filter((currentItem) => {
    return currentItem.optionId !== itemId;
  });

  const reduceInitialValue: { optionId: number }[] = [];
  const payload: { optionId: number }[] = newBasketItems.reduce(
    (acc, extrasBasketItemToRemove) => [
      ...acc,
      ...Array.from({ length: extrasBasketItemToRemove.count }, () => ({
        optionId: extrasBasketItemToRemove.optionId,
      })),
    ],
    reduceInitialValue
  );

  await fireItemRemovedToastFeedback(mutateAsync({ extras: payload }), itemCount, itemName, t);
};

export const calculateLineTotalByCount = ({ count, price }: { count: number; price?: number }) => {
  if (!price) {
    return 0;
  }
  return count * price;
};
