import { ServiceMessage, type ServiceMessageProps } from '@kaboodle-solutions/design-library';
import { toast, cssTransition, Id } from 'react-toastify';
import { create } from 'zustand';

export interface ToastProps extends ServiceMessageProps {
  autoClose?: number | boolean | undefined;
  allowDuplicate?: boolean;
  toastId?: Id;
  group?: string;
}

interface ToastState {
  groups: { [key: PropertyKey]: Id[] };
  addToast: (group: Id, id: Id) => void;
  removeToast: (group: Id, id: Id) => void;
}

export const fade = cssTransition({
  enter: 'toastFadeIn',
  exit: 'toastFadeOut',
});

export const useToastStore = create<ToastState>()((set) => ({
  // State
  groups: {},

  // Action to add a toast
  addToast: (group, id) =>
    set((state) => {
      const updatedGroups = { ...state.groups };

      // Only add the ID if it doesn't already exist in the group
      if (!updatedGroups[group]) {
        updatedGroups[group] = [id];
      } else if (!updatedGroups[group].includes(id)) {
        updatedGroups[group].push(id);
      }

      return { ...state, groups: updatedGroups };
    }),

  // Action to remove a toast
  removeToast: (group, id) =>
    set((state) => {
      const updatedGroups = { ...state.groups };

      // Only remove the ID if the group exists
      if (updatedGroups[group]) {
        updatedGroups[group] = updatedGroups[group].filter((existingId) => existingId !== id);
      }

      return { ...state, groups: updatedGroups };
    }),
}));

export const toastNotification = ({ testId, content, variant, autoClose, toastId, group }: ToastProps) => {
  const canAutoClose = autoClose !== undefined ? autoClose : true;

  const id = toast(<ServiceMessage testId={testId} content={content} variant={variant} />, {
    autoClose: canAutoClose === true ? 3000 : false,
    transition: fade,
    ...(toastId || group ? { toastId: `${variant || ''}:${group || ''}:${toastId || content || ''}` } : {}),
    ...(group ? { containerId: group } : {}),
  });

  const dismiss = () => toast.dismiss(id);

  return {
    id,
    dismiss,
  };
};
