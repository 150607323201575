type ClientThemeInfo = {
  theme: string;
  prefix: string;
};

const getThemeInfoFromClientId: {
  [key: number]: ClientThemeInfo;
} = {
  0: { theme: 'white-label', prefix: 'WL' },
  21: { theme: 'hideout', prefix: 'HO' },
  23: { theme: 'parklife', prefix: 'PL' },
  34: { theme: 'lost-village', prefix: 'LV' },
  41: { theme: 'field-day', prefix: 'FDF' },
  42: { theme: 'victorious', prefix: 'VCF' },
  44: { theme: 'kaboodle', prefix: 'KB' },
  55: { theme: 'kaboodle-labs', prefix: 'KB' },
  58: { theme: 'mighty-hoopla', prefix: 'MH' },
  61: { theme: 'boomtown', prefix: 'BT' },
  63: { theme: 'greenbelt', prefix: 'GB' },
  64: { theme: 'cross-the-tracks', prefix: 'CTT' },
  69: { theme: 'drumsheds', prefix: 'DS' },
  70: { theme: 'magazine', prefix: 'MZ' },
  77: { theme: 'exhibition', prefix: 'EX' },
  83: { theme: 'int-fitness-summit', prefix: 'IFS' },
  84: { theme: 'wychwood', prefix: 'WW' },
  86: { theme: 'warehouse-project', prefix: 'WHP' },
  89: { theme: 'city-splash', prefix: 'CSL' },
  94: { theme: 'we-out-here', prefix: 'WOH' },
  95: { theme: 'mucky-weekender', prefix: 'MW' },
  102: { theme: 'wide-awake', prefix: 'WA' },
  106: { theme: 'primavera-barca', prefix: 'PSB' },
  113: { theme: 'south-facing', prefix: 'SF' },
  115: { theme: 'silverworks-island', prefix: 'DY' },
  125: { theme: 'noisily', prefix: 'NF' },
  126: { theme: 'battersea-park-fireworks', prefix: 'BPF' },
  139: { theme: 'project-6', prefix: 'PR6' },
  143: { theme: 'stowaway', prefix: 'STO' },
  152: { theme: 'primavera-porto', prefix: 'PSP' },
  156: { theme: 'zimfest', prefix: 'ZIM' },
  157: { theme: 'the-cambridge-club', prefix: 'CC' },
  168: { theme: 'legacy-live', prefix: 'LL' },
  169: { theme: 'stories-in-the-park', prefix: 'SITP' },
  172: { theme: 'lumos-live', prefix: 'LUL' },
  173: { theme: 'broadwick-live', prefix: 'BWL' },
  181: { theme: 'beat-herder', prefix: 'BH' },
  182: { theme: 'sjm-concerts', prefix: 'SJM' },
  192: { theme: 'we-are-love', prefix: 'WAL' },
  194: { theme: 'adp', prefix: 'ADP' },
  201: { theme: 'dog-fest', prefix: 'DOG' },
  202: { theme: 'brick-lane-jazz-festival', prefix: 'BLJF' },
  204: { theme: 'lazydays', prefix: 'LZD' },
  205: { theme: 'soft-life-ski', prefix: 'SLS' },
  207: { theme: 'squid-game', prefix: 'SQG' },
  210: { theme: 'deer-shed', prefix: 'DSD' },
  211: { theme: 'sonar', prefix: 'SON' },
  214: { theme: 'hoopla-malta', prefix: 'HMALT' },
};

/**
 * Theme to be returned in case client does not have a custom theme
 */
const defaultTheme: ClientThemeInfo = { theme: 'kaboodle', prefix: 'KB' };

/**
 * This function returns the theme info each client ID points to
 * @returns - @theme the name of the theme file @prefix name of the folder that hold font declarations
 */
export const transformClientIdToClientName = (clientId: number | string | undefined | null): ClientThemeInfo => {
  if (!clientId) return defaultTheme;

  if (!getThemeInfoFromClientId[Number(clientId)]) return defaultTheme;

  return {
    theme: getThemeInfoFromClientId[Number(clientId)].theme,
    prefix: getThemeInfoFromClientId[Number(clientId)].prefix,
  };
};

export const createAndAddCssLink = (linkType: 'font' | 'theme', theme: string, cssRef: string) => {
  if (document.head.contains(document.querySelector(`.${theme}-${linkType}`))) return;

  const existingCss = document.querySelector(`#${linkType}-declaration`);

  if (document.head.contains(existingCss) && existingCss) {
    document.head.removeChild(existingCss);
  }

  // Create CSS Link
  const link = document.createElement('link');
  link.setAttribute('data-testid', `${linkType}-${theme}-link`);
  link.setAttribute('class', `${theme}-${linkType}`);
  link.setAttribute('id', `${linkType}-declaration`);

  link.rel = 'stylesheet';
  link.type = 'text/css';
  link.href = cssRef;
  document.head.appendChild(link);
};
