import { EngineContainer } from '@src/components/EngineContainer';
import styles from './authWrapper.module.scss';
import useBpTokenStore from '@src/store/useBpTokenStore';
import { useHandleAccessCode } from '@src/hooks/useHandleAccessCode/useHandleAccessCode';
import { useHandleRedirectAfterInit } from './AuthWrapper.helper';
import { useInitProcess } from '@src/hooks/useInitProcess/useInitProcess';
import useInitResponseStore from '@src/store/persistent/initResponse/useInitResponseStore';
import { useRef } from 'react';

function AuthWrapper() {
  const isFirstLoad = useRef(true);
  const { _hasInitResponseStoreHydrated } = useInitResponseStore();
  const { _hasBpTokenStoreHydrated } = useBpTokenStore();

  const { isInitLoading, isFirebaseAuthReady, isFirstBpTokenHeaderReady } = useInitProcess();

  const hasPersistentStoresHydrated = _hasBpTokenStoreHydrated && _hasInitResponseStoreHydrated;

  useHandleRedirectAfterInit({ isFirstLoad, isFirstBpTokenHeaderReady, isFirebaseAuthReady, isInitLoading });

  const { isAccessCodeReady } = useHandleAccessCode({
    hasInitFinished: isFirstBpTokenHeaderReady && isFirebaseAuthReady && !isInitLoading,
  });

  if (isInitLoading || !hasPersistentStoresHydrated) {
    return (
      <section className="appWrapperContainer">
        <div className={styles.authWrapper}>
          <p>{''}</p>
        </div>
      </section>
    );
  }

  if (isFirstBpTokenHeaderReady && isFirebaseAuthReady && isAccessCodeReady) {
    return <EngineContainer />;
  }

  return null;
}

export { AuthWrapper };
