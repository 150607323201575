import { KapiBasketEngineErrorHandles } from '@src/interfaces/engineData';

export const ALLOW_LIST_DOMAIN_VALIDATION_ERROR_REGEX = /Email domain is not allowed: .*@.*/;
export const ALLOW_LIST_ADDRESS_VALIDATION_ERROR_REGEX = /Customer email not in allowed email group \(email: .*@.*\)/;
export const MULTIPLE_BOOKINGS_VALIDATION_ERROR_REGEX = /Customer has existing bookings/;
export const INVALID_EMAIL_ERROR =
  'The email address you entered cannot be used to make a purchase using this event link';

export const CONTNUE_ONLY_ERROR_HANDLES = [
  KapiBasketEngineErrorHandles.TICKET_REQUIRED,
  KapiBasketEngineErrorHandles.EXTRA_REQUIRED,
  KapiBasketEngineErrorHandles.MANDATORY_EXTRA_REQUIRED,
  KapiBasketEngineErrorHandles.ACCOMMODATION_REQUIRED,
  KapiBasketEngineErrorHandles.TRANSPORT_REQUIRED,
  KapiBasketEngineErrorHandles.NO_ITEMS_IN_BASKET,
  KapiBasketEngineErrorHandles.TERMS_NOT_ACCEPTED, // Handled by Customer Details Form FE Validation
  KapiBasketEngineErrorHandles.PROTECTION_PLAN_NOT_SELECTED, // Handled by Customer Details Form
  KapiBasketEngineErrorHandles.MIN_EXTRA_QUANTITY_REQUIRED,
  KapiBasketEngineErrorHandles.DELIVERY_REQUIRED,
  KapiBasketEngineErrorHandles.MISSING_REQUIRED_CUSTOMER_DATA_CAPTURE, // Handled by Customer Details Form FE Validation
];
