import { createJSONStorage, persist } from 'zustand/middleware';

import { StripeRedirectParams } from '@src/constants';
import { create } from 'zustand';
import customStorageDefinition from '../customStorageDefinition';

const defaultStripeReturnUrlStoreValues = {
  [StripeRedirectParams.BASKET_ID]: null,
  [StripeRedirectParams.CLIENT_ID]: null,
  [StripeRedirectParams.PAYMENT_INTENT]: null,
  [StripeRedirectParams.PAYMENT_INTENT_SECRET]: null,
  [StripeRedirectParams.REDIRECT_STATUS]: null,
  [StripeRedirectParams.STRIPE_PUBLIC_KEY]: null,
  [StripeRedirectParams.GTM_IDS]: null,
  [StripeRedirectParams.META_IDS]: null,
};

type StripeReturnUrlParamsType = {
  // Hydrated flag
  _hasStripeReturnUrlStoreHydrated: boolean;
  setHasHydrated: (hasHydrated: boolean) => void;

  // State
  [StripeRedirectParams.BASKET_ID]: string | null;
  [StripeRedirectParams.PAYMENT_INTENT_SECRET]: string | null;
  [StripeRedirectParams.STRIPE_PUBLIC_KEY]: string | null;
  [StripeRedirectParams.CLIENT_ID]: string | null;
  [StripeRedirectParams.PAYMENT_INTENT]: string | null;
  [StripeRedirectParams.REDIRECT_STATUS]: string | null;
  [StripeRedirectParams.GTM_IDS]: string | null;
  [StripeRedirectParams.META_IDS]: string | null;

  // Actions
  setStripeReturnUrlStoreState: (stateToUpdate: `${StripeRedirectParams}`, newStateValue: string | null) => void;
  clearStripeReturnUrlStore: () => void;
};

const useStripeReturnUrlStore = create<StripeReturnUrlParamsType>()(
  persist(
    (set) => ({
      _hasStripeReturnUrlStoreHydrated: false,
      setHasHydrated: (hasHydrated) => {
        set({
          _hasStripeReturnUrlStoreHydrated: hasHydrated,
        });
      },

      // Default state loads everything as null
      ...defaultStripeReturnUrlStoreValues,

      // Update store actions
      clearStripeReturnUrlStore: () => set({ ...defaultStripeReturnUrlStoreValues }),
      setStripeReturnUrlStoreState: (stateToUpdate, newStateValue) => set({ [stateToUpdate]: newStateValue }),
    }),
    {
      name: 'stripe-return-url-persistent-storage',
      storage: createJSONStorage(() => customStorageDefinition),
      onRehydrateStorage: () => (state) => {
        state?.setHasHydrated(true);
      },
    }
  )
);

export default useStripeReturnUrlStore;
