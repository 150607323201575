import { InitResponse } from '@src/api/useInit';
import baseCode from './baseCode';
import Cookies from 'js-cookie';
import { getEnvironmentFromEnvironment } from '../getEnvironmentFromEnvironment';
import { KABOODLE_FALLBACK_FBC_COOKIE_NAME } from '@src/constants';

/**
 * Initialises Meta (Facebook) Pixel tracking by loading the base code, initialising
 * the provided Pixel IDs, and initialising a PageView event.
 *
 * @param {InitResponse['expanded']['metaPixelIds']} metaPixelIds - An array of Meta Pixel IDs to initialize.
 * @returns {void} This function does not return any value.
 */
export default function initialiseMetaTracking(metaPixelIds: InitResponse['expanded']['metaPixelIds']) {
  if (!metaPixelIds?.length || isFbqAvailable()) return;

  // Initialise the Meta Pixel base code
  baseCode();

  // Check params for `fbclid`
  addFallbackClickIdCookie();

  // Init each pixel ID
  metaPixelIds.forEach((id) => {
    window?.fbq?.('init', id);
  });

  // Initalise PageView tracking
  window?.fbq?.('track', 'PageView');
}

function isFbqAvailable(): boolean {
  return typeof window.fbq === 'function';
}

/**
 * Adds a fallback ClickID cookie with the format `fb.subdomainIndex.time.fbclid`.
 *
 * This is needed to store the click ID from the params if the `_fbc` cookie doesn't get populated
 * due to ad blockers or other issues.
 *
 * @see https://developers.facebook.com/docs/marketing-api/conversions-api/parameters/fbp-and-fbc/
 *
 * @function
 * @returns {void} Does not return any value.
 */
export function addFallbackClickIdCookie() {
  // If no click ID in the params, do nothing
  const clickIdParam = new URLSearchParams(window.location.search).get('fbclid');
  if (!clickIdParam) return;

  // If already stored, do nothing
  const existingClickIdCookie = Cookies.get(KABOODLE_FALLBACK_FBC_COOKIE_NAME);
  if (existingClickIdCookie?.endsWith(`.${clickIdParam}`)) return;

  // If cookie has changed or doesn't exist, set the cookie
  const environment = getEnvironmentFromEnvironment();
  const subdomainIndex = environment === 'development' || environment === 'testing' ? 0 : 2;
  const time = Date.now();
  Cookies.set(KABOODLE_FALLBACK_FBC_COOKIE_NAME, `fb.${subdomainIndex}.${time}.${clickIdParam}`, { expires: 90 });
}
