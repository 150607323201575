import { useCallback } from 'react';
import useInitResponseStore from '@src/store/persistent/initResponse/useInitResponseStore';
import { useNavigate } from 'react-router-dom';

/**
 * This hook is responsible for persisting the init params after navigation
 */

export const useNavigateWithQueryParams = () => {
  const navigate = useNavigate();
  const { initParams, accessCode, options } = useInitResponseStore();
  const accessCodePackageOptionEnabled = Boolean(options?.GATED_ACCESS);

  const navigateWithQueryParams = useCallback(
    (path: string | number, includeAccessCode = false) => {
      includeAccessCode && accessCode && accessCodePackageOptionEnabled
        ? navigate(`${path}${initParams || ''}&ac=${accessCode}`)
        : navigate(`${path}${initParams || ''}`);
    },
    [navigate, initParams, accessCode, accessCodePackageOptionEnabled]
  );

  return { navigateWithQueryParams };
};
