import { create } from 'zustand';
import {
  PackageTransportStop,
  TransportDate,
  TransportDirectionOption,
  TransportTypeOption,
} from '@src/interfaces/transport';
import { BasketTransport } from '@src/interfaces/engineBasketPutRequest';

interface TransportState {
  selectedType: TransportTypeOption | null;
  travelType: TransportDirectionOption | null;
  departDate: TransportDate | null;
  departLocation: number | null;
  arrivalLocation: number | null;
  selectedOutStop: PackageTransportStop | null;
  selectedReturnStop: PackageTransportStop | null;
  customers: number;
  basketTransport: BasketTransport | null;
  setBasketTransport: (transport: BasketTransport | null) => void;
  setSelectedType: (type: TransportTypeOption | null) => void;
  setTravelType: (type: TransportDirectionOption | null) => void;
  setDepartDate: (date: TransportDate | null) => void;
  setDepartLocation: (locationId: number | null) => void;
  setArrivalLocation: (locationId: number | null) => void;
  setSelectedOutStop: (stop: PackageTransportStop | null) => void;
  setSelectedReturnStop: (stop: PackageTransportStop | null) => void;
  setCustomers: (count: number) => void;
  resetTransportState: () => void;
  calculateTotalPrice: () => number;
  hasBasketTransportChanged: boolean;
  setHasBasketTransportChanged: (changed: boolean) => void;
}

const initialState = {
  selectedType: null,
  travelType: null,
  departDate: null,
  departLocation: null,
  arrivalLocation: null,
  selectedOutStop: null,
  selectedReturnStop: null,
  customers: 1,
  basketTransport: null,
  hasBasketTransportChanged: false,
};

export const useTransportStore = create<TransportState>()((set, get) => ({
  ...initialState,

  setSelectedType: (type) => set({ selectedType: type }),

  setTravelType: (type) => set({ travelType: type }),

  setDepartDate: (date) => set({ departDate: date }),

  setDepartLocation: (locationId) => set({ departLocation: locationId }),

  setArrivalLocation: (locationId) => set({ arrivalLocation: locationId }),

  setSelectedOutStop: (stop) => set({ selectedOutStop: stop }),

  setSelectedReturnStop: (stop) => set({ selectedReturnStop: stop }),

  setCustomers: (count) => set({ customers: count }),

  resetTransportState: () =>
    set((state) => ({
      ...state,
      travelType: TransportDirectionOption.BOTH,
      departDate: undefined,
      departLocation: null,
      arrivalLocation: null,
      selectedOutStop: null,
      selectedReturnStop: null,
    })),

  setBasketTransport: (transport) => {
    set((state) => {
      if (transport === null) {
        // Reset all state  except selectedType when basket transport is cleared
        return {
          ...state,
          travelType: TransportDirectionOption.BOTH,
          departDate: null,
          departLocation: null,
          arrivalLocation: null,
          selectedOutStop: null,
          selectedReturnStop: null,
          basketTransport: null,
          hasBasketTransportChanged: false,
        };
      }
      return { ...state, basketTransport: transport };
    });
  },
  setHasBasketTransportChanged: (changed) => set({ hasBasketTransportChanged: changed }),

  calculateTotalPrice: () => {
    const state = get();
    let totalPrice = 0;

    if (state.selectedOutStop) {
      totalPrice += state.selectedOutStop.price.value * state.customers;
    }

    if (state.selectedReturnStop) {
      totalPrice += state.selectedReturnStop.price.value * state.customers;
    }

    return totalPrice;
  },
}));
