import { formatDate } from 'date-fns';
import { type BasketItemLeg } from '../BasketContent.types';
import { type Namespace, type TFunction } from 'i18next';
import { Typography } from '@kaboodle-solutions/design-library';
import styles from '../basketContent.module.scss';

type TransportLegDisplayStrings = {
  location?: string;
  displayDate?: string;
  displayTime?: string;
};

export const generateTransportBasketDisplayStrings = (
  leg: BasketItemLeg | undefined,
  t: TFunction<Namespace, undefined, Namespace>
) => {
  const legDetails: TransportLegDisplayStrings = {};

  if (leg) {
    legDetails.location = `${leg?.departureLocation} ${t('common:words.to')} ${leg.arrivalLocation}`;

    if (leg.date) {
      legDetails.displayDate = formatDate(String(leg.date), 'EEE d MMM uuuu');
    }

    legDetails.displayTime = leg.time ?? t('transportEngine:stops.tbc');
  }

  return legDetails;
};

export const LegDetails = ({ details }: { details: TransportLegDisplayStrings }) => {
  return (
    <div>
      <Typography tag="span" customClass={styles.basketItem_name}>
        {details.location}
      </Typography>
      <Typography type="subtext" customClass={styles.basketItem_transportsDateTime}>
        {details.displayTime} ({details.displayDate})
      </Typography>
    </div>
  );
};
