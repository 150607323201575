import { useTransportQueries } from './useTransportQueries';
import {
  TransportType,
  KapiPackageTransportDate,
  KapiPackageTransportLocation,
  PackageTransportStop,
} from '@src/interfaces/transport';
import { useTransportMutation } from '@src/hooks/useTransport/useTransportMutation';
import { useTransportStore } from '@store/useTransportStore';

export const useTransport = () => {
  const {
    fetchTransportTypes,
    fetchTransportDates,
    fetchTransportLocations,
    fetchTransportStops,
    setTransportType,
    setTransportDirection,
    setTransportDate,
    setDepartureLocation,
    setArrivalLocation,
  } = useTransportQueries();

  const { mutateBasketWithTransport, isPending } = useTransportMutation();

  // Utility function to check if a transport option can be selected
  const canSelectTransport = (transportStop: PackageTransportStop, selectedStops: PackageTransportStop[]): boolean => {
    return transportStop.available > 0 && !selectedStops.some((stop) => stop.stopId === transportStop.stopId);
  };

  // Utility function to format transport information for display
  const formatTransportInfo = (
    type: TransportType,
    date: KapiPackageTransportDate,
    location: KapiPackageTransportLocation,
    stop: PackageTransportStop
  ): string => {
    return `${type.name} from ${location.name} on ${date.date} at ${stop.time}`;
  };

  const {
    selectedType,
    travelType,
    departDate,
    departLocation,
    arrivalLocation,
    selectedOutStop,
    selectedReturnStop,
    customers,
    basketTransport,
    setSelectedType,
    setTravelType,
    setDepartDate,
    setDepartLocation,
    setArrivalLocation: setArrivalLocationInStore,
    setSelectedOutStop,
    setSelectedReturnStop,
    setCustomers,
    calculateTotalPrice,
    resetTransportState,
    setBasketTransport,
    hasBasketTransportChanged,
    setHasBasketTransportChanged,
  } = useTransportStore();

  return {
    fetchTransportTypes,
    fetchTransportDates,
    fetchTransportLocations,
    fetchTransportStops,
    setTransportType,
    setTransportDirection,
    setTransportDate,
    setDepartureLocation,
    setArrivalLocation,
    addToBasket: mutateBasketWithTransport,
    isPending,
    canSelectTransport,
    formatTransportInfo,
    selectedType,
    travelType,
    departDate,
    departLocation,
    arrivalLocation,
    selectedOutStop,
    selectedReturnStop,
    customers,
    basketTransport,
    setSelectedType,
    setTravelType,
    setDepartDate,
    setDepartLocation,
    setArrivalLocationInStore,
    setSelectedOutStop,
    setSelectedReturnStop,
    setCustomers,
    calculateTotalPrice,
    resetTransportState,
    setBasketTransport,
    hasBasketTransportChanged,
    setHasBasketTransportChanged,
  };
};
