import { BPAPI } from '@src/api/mockedApi';
import {
  KapiPackageTransportDate,
  KapiPackageTransportLocation,
  PackageTransportStop,
  TransportDate,
  TransportDirectionOption,
  TransportType,
  TransportTypeOption,
} from '@src/interfaces/transport';

export const getTransportTypes = async (groupId?: number) => {
  const queryParams = groupId ? { groupId } : {};
  return await BPAPI.get<TransportType[]>('/transport/types', { params: queryParams });
};

export const getTransportDates = async (type: TransportTypeOption, direction: TransportDirectionOption) => {
  const queryParams = { type, direction };
  return await BPAPI.get<KapiPackageTransportDate[]>('/transport/dates', { params: queryParams });
};

export const getTransportLocations = async (
  type: TransportTypeOption,
  direction: TransportDirectionOption,
  date: TransportDate
) => {
  const queryParams = { type, direction, date };
  return await BPAPI.get<KapiPackageTransportLocation[]>('/transport/locations', { params: queryParams });
};

export const getTransportStops = async (
  type: TransportTypeOption,
  direction: TransportDirectionOption,
  date: TransportDate,
  departureLocation: number,
  arrivalLocation: number
) => {
  const queryParams = {
    type,
    direction,
    date,
    departureLocation,
    arrivalLocation,
  };
  return await BPAPI.get<PackageTransportStop[]>('/transport/stops', { params: queryParams });
};
