import { BaseSyntheticEvent } from 'react';
import { TEngineBasketPutRequest } from '@src/interfaces/engineBasketPutRequest';

export enum KapiEngineHandlesOptions {
  ACCOMMODATION = 'ACCOMMODATION',
  BASKET = 'BASKET',
  CONSENTS = 'CONSENTS',
  CUSTOMER_DETAILS = 'CUSTOMER_DETAILS',
  DELIVERY = 'DELIVERY',
  DISCOUNTS = 'DISCOUNTS',
  EXTRAS = 'EXTRAS',
  INSURANCE = 'INSURANCE',
  LOGIN = 'LOGIN',
  PACKAGE = 'PACKAGE',
  PAYMENT = 'PAYMENT',
  PROTECTION_PLAN = 'PROTECTION_PLAN',
  TICKET = 'TICKET',
  TRANSPORT = 'TRANSPORT',
  ACCESS_CODE = 'ACCESS_CODE',
}

export enum KapiBasketEngineErrorHandles {
  DEFAULT = 'DEFAULT',
  COMPLEX_RULE = 'COMPLEX_RULE',
  ADULT_TICKET_REQUIRED = 'ADULT_TICKET_REQUIRED',
  CHILD_TICKET_REQUIRED = 'CHILD_TICKET_REQUIRED',
  DISCOUNT_CODE_INVALID = 'DISCOUNT_CODE_INVALID',
  TERMS_NOT_ACCEPTED = 'TERMS_NOT_ACCEPTED',
  PROTECTION_PLAN_NOT_SELECTED = 'PROTECTION_PLAN_NOT_SELECTED',
  EXTRA_REQUIRED = 'EXTRA_REQUIRED',
  TICKET_REQUIRED = 'TICKET_REQUIRED',
  MIN_EXTRA_QUANTITY_REQUIRED = 'MIN_EXTRA_QUANTITY_REQUIRED',
  MANDATORY_EXTRA_REQUIRED = 'MANDATORY_EXTRA_REQUIRED',
  EXTRA_DECLARATION_REQUIRED = 'EXTRA_DECLARATION_REQUIRED',
  ACCOMMODATION_REQUIRED = 'ACCOMMODATION_REQUIRED',
  MISSING_REQUIRED_TICKET_DATA_CAPTURE = 'MISSING_REQUIRED_TICKET_DATA_CAPTURE',
  NO_ITEMS_IN_BASKET = 'NO_ITEMS_IN_BASKET',
  DELIVERY_REQUIRED = 'DELIVERY_REQUIRED',
  BELOW_MINIMUM_AGE = 'BELOW_MINIMUM_AGE',
  NO_ACCESS_CODE = 'NO_ACCESS_CODE',
  TRANSPORT_REQUIRED = 'TRANSPORT_REQUIRED',
  MISSING_REQUIRED_CUSTOMER_DATA_CAPTURE = 'MISSING_REQUIRED_CUSTOMER_DATA_CAPTURE',
}

export type KapiEngineHandles = `${KapiEngineHandlesOptions}`;

export interface EngineMsgItem {
  categoryId: number;
  message: string;
}

export interface EngineItem {
  handle: KapiEngineHandles;
  mandatory: boolean;
  messages?: string[];
  name: string;
}

export interface EngineData {
  [key: string]: EngineItem[];
}

export interface InitResponse {
  data?: {
    engines?: EngineData;
    options: {
      NKBF_MAXTICKETS: number;
      STRIPE_PUBLIC_API: string;
    };
  };
  isSuccess: boolean;
}

export interface packageOptions {
  maxSelection: number;
}

type ContinueButtonCallback =
  | ((params?: unknown) => void)
  | ((params?: unknown) => Promise<void>)
  | ((e?: BaseSyntheticEvent | undefined) => Promise<void>);

export type EngineContext = {
  enableContinueButton: boolean;
  continueButtonCallback?: ContinueButtonCallback;
  continueButtonVariant?: 'alternative' | 'danger' | 'primary' | 'secondary' | 'success' | 'disabled';
  isTicketStateDirty?: boolean;
  getBasketRequestState?: () => Promise<TEngineBasketPutRequest>;
  resetTicketState?: () => void;
  showBasket: boolean;
  showCompleteBookingLabel: boolean;
};

export type EngineInfo = {
  handle: string;
  name: string;
  route: string;
};
